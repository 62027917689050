import React, { useContext, useEffect, useState } from 'react'
import SideMenu from '../components/sidemenu'
import { Await, useLocation, useNavigate } from 'react-router-dom';
import { BiArrowBack, BiRefresh } from "react-icons/bi";
import { AiFillCloseCircle, AiOutlineTag, AiOutlineTags } from 'react-icons/ai';
import { Service } from '../services/api';
import { YYYYMMDD, date } from '../utils/utils';
import { AlertContext } from '../context/alert_context';
import Renderif from '../components/renderif';
import { AuthContext } from '../context/auth_context';
import { MdOutlineSwapHorizontalCircle } from "react-icons/md";
import Loader from '../components/loader';
import { FiArrowRightCircle } from "react-icons/fi";

export default function DetallePlanView() {

    const location = useLocation()
    const { user, logout } = useContext(AuthContext)

    let _day = location.state?.day_active

    _day = _day !== undefined ? _day : new Date()

    const navigate = useNavigate();
    const { notify } = useContext(AlertContext)
    const [state, setState] = useState({
        loading: true,
        button_option: 0,
    })

    useEffect(() => {
        // init()
        return () => { }
    }, [])

    useEffect(() => {




        const init = async () => {
            setState({ ...state, loading: false })
        }
        init();



        return () => { }

    }, [state.day_active])

    useEffect(() => {
        const init = async () => {

        }
        init()
        return () => { }

    }, [state.recipe_active])





    const _init = async () => {



    }



    return (
        <div className='container-fluid d-flex h-100'>
            <Renderif isTrue={state.loading}>
                <Loader></Loader>
            </Renderif>

            <div>
                <SideMenu page={'comidas'}></SideMenu>
            </div>

            <div className='col h-100 overflow-auto hide-scroll'>

                <div className='container-fluid bg-white sticky-top'>
                    <img className='m-3' width={120} src='../assets/images/logo.png'></img>
                </div>


                <div className='container-fluid pb-5'>

                    <div className='container-fluid mx-auto p-3'>
                        <div className='tip-full-aux d-flex flex-column' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/9ea76885-6fac-4816-b46a-5688d2232c3d.jpg)` }}>
                            <div className='col'>

                            </div>
                            <div className='flex-center p-2'>
                                <div className={`btn-plan r-10 py-1 px-2 m-2 cursor-pointer`} onClick={() => {
                                    setState({ ...state, button_option: 0 });
                                }}>
                                    Detalle del plan
                                </div>
                                <div className={`btn-plan r-10 py-1 px-2 m-2 cursor-pointer`} onClick={() => {
                                    setState({ ...state, button_option: 1 });
                                }}>
                                    Curso
                                </div>
                                <div className={`btn-plan r-10 py-1 px-2 m-2 cursor-pointer`} onClick={() => {
                                    setState({ ...state, button_option: 2 });
                                }}>
                                    Comunidad
                                </div>
                            </div>
                        </div>
                        <Renderif isTrue={state.button_option === 0}>
                            <div className='m-sm-5'>
                                <div className='h4'>
                                    Acerca del plan
                                </div>
                                <div>
                                    What is Lorem Ipsum?
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    What is Lorem Ipsum?
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    What is Lorem Ipsum?
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    What is Lorem Ipsum?
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </div>
                            </div>
                        </Renderif>

                        <Renderif isTrue={state.button_option === 1}>

                        </Renderif>

                        <Renderif isTrue={state.button_option === 2}>
                            <div className='m-sm-5'>
                                <div className='flex-center'>
                                    Realiza una pregunta o comentrario en el tema.
                                </div>
                                <div className='px-sm-5'>
                                    <div className='bv-pill--light-txt-black mx-sm-5 mb-2 d-flex p-3'>

                                        <div>
                                            Pregunta 1
                                        </div>
                                        <div className='col'>

                                        </div>
                                        <div>
                                            <FiArrowRightCircle size={25}/>
                                        </div>
                                    </div>
                                    <div className='bv-pill--light-txt-black mx-sm-5 mb-2 d-flex p-3'>
                                        <div>
                                            Pregunta 1
                                        </div>
                                        <div className='col'>

                                        </div>
                                        <div>
                                            <FiArrowRightCircle size={25}/>
                                        </div>
                                    </div>
                                    <div className='bv-pill--light-txt-black mx-sm-5 mb-2 d-flex p-3'>
                                        <div>
                                            Pregunta 1
                                        </div>
                                        <div className='col'>

                                        </div>
                                        <div>
                                            <FiArrowRightCircle  size={25}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Renderif>
                    </div>

                </div>

            </div>

        </div>
    )
}
