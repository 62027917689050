import React, { useContext, useEffect, useState } from 'react'
import SideMenu from '../components/sidemenu'
import { useNavigate } from 'react-router-dom';
import { Service } from '../services/api';
import { AuthContext } from '../context/auth_context';
import Renderif from '../components/renderif';
import { AiFillCloseCircle } from 'react-icons/ai'

export default function TemasView() {
    const navigate = useNavigate();

    const { user, logout } = useContext(AuthContext)
    // console.log(user)

    const [state, setState] = useState({
        courses: [],
        description_modal: false,
        plan: [],
        loading: false,
        planes_modal: false,
        success: false,
    })

    useEffect(() => {


        init();

        return () => { }
    }, [])

    const init = async () => {
        setState({ ...state, loading: true })
        // let _planes = await Service('GET', 'plans');
        let _courses = await Service('GET', 'courses');

        console.log(_courses.data)

        if (_courses.status === 200) {
            setState({ ...state, courses: _courses.data })
        } else if (_courses.status === 401) {
            logout();
            navigate('/')
        }
        // console.log(_planes.data.filter((plan) => plan.specialty === "NUTRITION"))
    }

    const checkPlan = (plan) => {
        console.log(plan)

        let some = false;
        if (user?.subscriptions.length === 0) {
            return false
        }

        let _user_subs = []

        user?.subscriptions.map((sub) => {
            _user_subs.push(sub.name)
        })

        let _plan_subs = []

        plan.stripeSubscriptions.map((sub) => {
            _plan_subs.push(sub.stripeSubscription.name)
        })

        _user_subs.map((user_sub) => {
            if (_plan_subs.includes(user_sub)) {
                some = true;
            }
        })






        if (some) {
            return true;
        } else {
            return false
        }
    }

    return (
        <div className='container-fluid d-flex h-100'>

            <div>
                <SideMenu page={'home'}></SideMenu>
            </div>
            <Renderif isTrue={state.planes_modal}>
                <div className='overlay d-flex justify-content-center align-items-center'>
                    <div className='bv-modal shadow'>
                        <div className='px-4 pt-3 d-flex align-items-center'>
                            <div className=' f-16 px-4 pt-3 text-gray bolder'> </div>
                            <div className='col'></div>
                            <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                                setState({ ...state, planes_modal: false })

                            }}> <AiFillCloseCircle size={24} color="#00BF6B"></AiFillCloseCircle></div>
                        </div>
                        <div className='px-4 pb-5 d-sm-flex'>
                            <div className='col'></div>
                            <div className='txt-white'>
                                <div className='bg-gradient-red p-5 r-10'>
                                    <div className='bold text-center f-24 lh-1'>
                                        PLAN
                                    </div>
                                    <div className='bold text-center f-24 lh-1'>
                                        MENSUAL
                                    </div>
                                    <div className='my-3 lh-1'>
                                        <div className='bold text-center f-66'>
                                            $150
                                        </div>
                                        <div className='text-center'>
                                            POR MES
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        PAGO TOTAL $150
                                    </div>
                                </div>
                                <div className='text-center mt-2 bold f-22 text-green'>
                                    Comprar Ahora
                                </div>
                            </div>
                            <div className='col'></div>
                            <div className='txt-white'>
                                <div className='bg-gradient-green p-5 r-10'>
                                    <div className='bold text-center f-24 lh-1'>
                                        PLAN
                                    </div>
                                    <div className='bold text-center f-24 lh-1'>
                                        TRIMESTRAL
                                    </div>
                                    <div className='my-3 lh-1'>
                                        <div className='bold text-center f-66'>
                                            $99
                                        </div>
                                        <div className='text-center'>
                                            POR MES
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        PAGO TOTAL $300
                                    </div>
                                </div>
                                <div className='text-center mt-2 bold f-22 text-green'>
                                    Comprar Ahora
                                </div>
                            </div>
                            <div className='col'></div>
                        </div>

                    </div>
                </div>
            </Renderif>

            <Renderif isTrue={state.success}>
                <div className='overlay d-flex justify-content-center align-items-center'>
                    <div className='bv-modal shadow'>
                        <div className='px-4 pt-3 d-flex align-items-center'>
                            <div className=' f-16 px-4 pt-3 text-gray bolder'> </div>
                            <div className='col'></div>
                            <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                                setState({ ...state, success: false })

                            }}> <AiFillCloseCircle size={24} color="#00BF6B"></AiFillCloseCircle></div>
                        </div>
                        <div className='px-4 pb-5 text-center'>
                            <div className='f-32 bold text-green'>
                                ¡Compra exitosa!
                            </div>
                            <div className='my-5'>
                                <div className='text-gray'>
                                    Tu suscripción "Nombre del plan" se ha realizado con  éxito.
                                </div>
                                <div className='text-gray'>
                                    Gracias por confiar en nosotros
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div className='col'></div>
                                <div className='bv-cta bv-cta--success mt-2 w-25 align-self-end' onClick={() => {
                                    // navigate('/user/agendar')
                                    // setState({ ...state, planes_modal: true })
                                }}>
                                    Iniciar aprendizaje
                                </div>
                                <div className='col'></div>
                                <div className='bv-cta bv-cta--success mt-2 w-25 align-self-end' onClick={() => {
                                    // navigate('/user/agendar')
                                    // setState({ ...state, planes_modal: true })
                                }}>
                                    Seguir explorando
                                </div>
                                <div className='col'></div>
                            </div>
                        </div>

                    </div>
                </div>
            </Renderif>

            <Renderif isTrue={state.description_modal}>
                <div className='overlay d-flex justify-content-center align-items-center'>
                    <div className='bv-modal shadow'>
                        <div className='px-4 pt-3 d-flex align-items-center'>
                            <div className='f-16 text-gray'> </div>
                            <div className='col'></div>
                            <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                                setState({ ...state, description_modal: false })

                            }}> <AiFillCloseCircle size={24} color="#00BF6B"></AiFillCloseCircle></div>
                        </div>
                        <div className='px-4 pb-5'>
                            <div className='plan-card-desc' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${state.plan?.image?.name})` }}></div>
                            <div className='px-4 pt-3 d-flex align-items-center'>
                                <div className='f-16 text-green bolder p-1'>{state.plan?.title}</div>
                                <div className='col'></div>
                                <div className={`bv-cta bv-cta--success`} onClick={() => {
                                    navigate('/user/precios')
                                }}>
                                    Ir al Plan

                                </div>
                            </div>
                            <div className='mt-3  overflow-auto max-h-400'>
                                {state.plan?.descripcion}
                            </div>
                        </div>
                    </div>
                </div>
            </Renderif>

            <div className='col h-100 overflow-auto hide-scroll'>

                <div className='container-fluid bg-white sticky-top '>
                    <img className='m-3' width={120} src='../assets/images/logo.png'></img>
                </div>
                <div className='container-fluid container-xl mx-auto px-3'>
                    <div className='f-28 text-gray bold'>Explora Nuestros </div>
                    <div className='f-28 text-gray bold'> Planes Alimenticios</div>
                    {/* <div className='f-16 text-gray'>Tips y recetas sencillas elaboradas por nutriólogas certificadas</div> */}
                </div>
                <div className='container-fluid container-xl mx-auto'>
                    <div className='row'>
                        {
                            state.courses.map((item, i) => {
                                return (
                                    <div key={i} className='col-12 col-xl-3 px-3 pb-3 d-flex flex-column'>
                                        <div className='plan-card-square-home r-8' style={{ backgroundImage: ` url(${item?.name})` }} onClick={() => {


                                            if (checkPlan(item)) {
                                                // navigate('/user/plan', { state: { item } })
                                                navigate('/user/tema/detalle', { state: { curso: item } })
                                            } else {
                                                setState({ ...state, description_modal: true })
                                            }

                                        }}>
                                            <div className='layout-cover'></div>
                                        </div>
                                        <div className=''>
                                            {/* <div className='d-flex-center'>
                                                <div className='f-18  bolder'>{item.title}</div>
                                                <div className='col'></div>
                                                <div className='bv-cta bv-cta--success' onClick={() => {
                                                    // navigate('/user/agendar')
                                                    setState({ ...state, planes_modal: true })
                                                }}>
                                                    Comprar Plan

                                                </div>
                                            </div> */}
                                            <div className='f-12 mt-2 text-justify text-gray max-plan-content'><div dangerouslySetInnerHTML={{ __html: item.description }}></div></div>

                                        </div>
                                        <div className='col'>
                                        </div>
                                        <div className='bv-cta bv-cta--success mt-2 w-100 align-self-end' onClick={() => {
                                            navigate('/user/tema/detalle', { state: { curso: item } })
                                            // setState({ ...state, planes_modal: true })
                                        }}>
                                            Ingresar

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}
