import React, { useContext, useState } from 'react'


import { MdFamilyRestroom,MdTipsAndUpdates } from 'react-icons/md';
import { IoIosDocument } from 'react-icons/io';
import { CgProfile,CgLogOut,CgMenu } from 'react-icons/cg';
import { AiOutlineQuestionCircle} from 'react-icons/ai'


import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/auth_context';
import Renderif from './renderif';
import { BsPerson, BsPersonCircle } from 'react-icons/bs';
import { RiHeart2Line } from "react-icons/ri";
import { PiForkKnifeBold } from "react-icons/pi";
import { IoCalendarOutline } from "react-icons/io5";
import { MdPersonPin } from "react-icons/md";



export default function SideMenu({page}) {
    const navigate = useNavigate();
    const {setUser, user, logout} = useContext(AuthContext)
    const [state, setState] = useState({
        show_appsubmenu:false
    })

  return (
    <>
       
            <div className='bv_sidemenu'>

            <div className={`bv_sidemenu_item my-2 ${page === 'home' ? 'bv_sidemenu_item--active': ''}`}
                onClick={()=>{
                    navigate('/user')
                }}>
                <RiHeart2Line size={40}/>
                <div className='text-gray f-13'>My diet</div>
            </div>

            <div className={`bv_sidemenu_item my-2 ${page === 'comidas' ? 'bv_sidemenu_item--active': ''}`}
                onClick={()=>{
                    navigate('/user/comidas')
                }}>
                <PiForkKnifeBold size={40}/>
                <div className='text-gray f-13'>Comidas</div>
            </div>

            <div className={`bv_sidemenu_item my-2 ${page === 'citas' ? 'bv_sidemenu_item--active': ''}`}
                onClick={()=>{
                    navigate('/user/citas')
                }}>
                <IoCalendarOutline size={40}/>
                <div className='text-gray f-13'>Citas</div>
            </div>

            <div className={`bv_sidemenu_item my-2 ${page === 'perfil' ? 'bv_sidemenu_item--active': ''}`}
                onClick={()=>{
                    navigate('/user/perfil')
                }}>
                <MdPersonPin size={40}/>
                <div className='text-gray f-13'>Perfil</div>
            </div>

            {/* <div className={`bv_sidemenu_item ${page === 'perfil' ? 'bv_sidemenu_item--active': ''}`}
                onClick={()=>{
                    navigate('/user/detalleplan')
                }}>
                <MdPersonPin size={40}/>
                <div className='text-gray f-13'>detallePlan</div>
            </div> */}
            {/* <div className={`bv_sidemenu_item ${page === 'perfil' ? 'bv_sidemenu_item--active': ''}`}
                onClick={()=>{
                    navigate('/user/home')
                }}>
                <MdPersonPin size={40}/>
                <div className='text-gray f-13'>temporal</div>
            </div> */}

     

        

  

            <div className='col'></div>

            <div className='bv_sidemenu_item'>
                <AiOutlineQuestionCircle size={40} color="white" />
                <div className='text-white f-12'>Faq</div>
            </div>
                
            <div className='bv_sidemenu_item' onClick={ ()=>{
                logout();
                navigate('/')
            }}>
                <CgLogOut size={40} color="white" />
                <div className='text-white f-12 opacity-0'>empty</div>
            </div>

 



            </div>
   


  
    </>

  )
}
// export function AdminSideMenu() {
//     const navigate = useNavigate();
//     const {setUser, user, logout} = useContext(AuthContext)
//     const [state, setState] = useState({
//         show_appsubmenu:false
//     })

//   return (
//     <>
//         <div className='d-none d-md-block d-none'>
//             <div className='bv_sidemenu'>

//             <div className='bv_sidemenu_item bv_sidemenu_item--active'
//             onClick={()=>{
              
//                     navigate('/administrador')
               
        
//             }}>
//                 <AiFillHome size={40} color="white" />
//                 <div className='text-white f-12'>Home</div>
//             </div>


//             <div className='bv_sidemenu_item bv_sidemenu_item--active ' onClick={()=>{
               
            
//                     navigate('/administrador/citas')
            
                
//             }}>
//                 <BsCalendar size={22} color="white" />
//                 <div className='text-white f-12'>Citas</div>
//             </div>

        
//             <div className='bv_sidemenu_item bv_sidemenu_item--active'
//             onClick={()=>{
              
//                     navigate('/administrador/especialistas')
               
        
//             }}>
//                 <BsPersonCircle size={40} color="white" />
//                 <div className='text-white f-12'>Usuarios</div>
//             </div>

//             <div className='bv_sidemenu_item bv_sidemenu_item--active' onClick={()=>{
               
            
//                navigate('/administrador/tips')
       
           
//        }}>
//                 <MdTipsAndUpdates size={40} color="white" />
//                 <div className='text-white f-12'>Tips</div>
//             </div>

//             <div className='bv_appmenu_item bv_sidemenu_item--active'onClick={()=>{
//                       navigate('/administrador/documentos')
//             }}>
                
//                 <div className='bv-icon bv-icon--mid m-0'>
//                     <IoIosDocument size={24} color="white" />
//                 </div>
//                 <div className='text-white f-12'>Documentos</div>
//             </div>

        

     


      

//             <div className='col'></div>

//             <div className='bv_sidemenu_item'>
//                 <AiOutlineQuestionCircle size={40} color="white" />
//                 <div className='text-white f-12'>Faq</div>
//             </div>
                
//             <div className='bv_sidemenu_item' onClick={ ()=>{
//                 logout();
//                 navigate('/')
//             }}>
//                 <CgLogOut size={40} color="white" />
//                 <div className='text-white f-12 opacity-0'>empty</div>
//             </div>

 



//             </div>
//         </div>


//         <div className='d-block d-md-none'>
//             {/* <Renderif isTrue={state?.show_appsubmenu}>
//             <div className='bv_appmenu_submenu'>

//                 <div className='bv_appmenu_item'onClick={()=>{

//                     logout()
//                     navigate('/')

                    
//                 }}>
//                     <div className='bv-icon bv-icon--mid m-0'>
//                         <CgLogOut size={22}  color="white" />
//                     </div>
                
//                     <div className='text-white f-12'>Salir</div>
//                 </div>

//                 <div className='bv_appmenu_item'onClick={()=>{
//                           navigate('/client/family')
//                 }}>
                
//                     <div className='bv-icon bv-icon--mid m-0'>
//                         <MdFamilyRestroom size={24} color="white" />  
//                     </div>
//                     <div className='text-white f-12'>Falimia</div>
//                 </div>
//                 <div className='bv_appmenu_item'onClick={()=>{
//                           navigate('/client/profile')
//                 }}>
                
//                     <div className='bv-icon bv-icon--mid m-0'>
//                         <CgProfile size={24} color="white" />  
//                     </div>
//                     <div className='text-white f-12'>Perfil</div>
//                 </div>

//             </div>
//             </Renderif> */}
//             <div className='bv_appmenu'>

//                 <div className='bv_appmenu_item'onClick={()=>{
         
//                         navigate('/administrador')
                  

                 
//                 }}>
//                     <div className='bv-icon bv-icon--mid m-0'>
//                         <AiFillHome size={22}  color="white" />
//                     </div>
                
//                     <div className='text-white f-12'>Home</div>
//                 </div>




            

//             </div>
//         </div>
  
//     </>

//   )
// }
