import React, { useContext, useEffect, useState } from 'react'
import SideMenu from '../components/sidemenu'
import { useLocation, useNavigate } from 'react-router-dom';
import { Service } from '../services/api';
import { AuthContext } from '../context/auth_context';
import Renderif from '../components/renderif';
import { AiFillCloseCircle } from 'react-icons/ai'
import { IoArrowBackCircle } from "react-icons/io5";
import { IoIosArrowDropupCircle, IoIosArrowDropdownCircle } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { PiSpeakerHighBold } from "react-icons/pi";
import { RxSize } from "react-icons/rx";
import axios from 'axios';

export default function TemaDetalleView() {
    const navigate = useNavigate();
    const location = useLocation();
    const _curso = location?.state?.curso;
    // console.log("curso->",_curso)
    console.log(JSON.parse(_curso?.data))

    const { user, logout } = useContext(AuthContext)

    const [state, setState] = useState({
        planes: [],
        description_modal: false,
        plan: [],
        loading: false,
        planes_modal: false,
        success: false,
        btn_select: 1,
        semanas: [],
        mostrar_semana: 0,
        sub_tema_select_index: null,
        mostrar_dia: null,
        sub_tema_select: 1,
        tema_dia: null,
        curso: [],
        curso_progress: [],
        tips: {},
        tip_asset: null,
        full_screen:false,
    })

    useEffect(() => {


        init();

        return () => { }
    }, [])

    const init = async () => {
        const ACCESS_TOKEN = "rY46Ylrq96wuj2fPp90ok5Ku96C0LctV3j-furqSwCM";
        const SPACE_ID = "cyupqx8f0n3c";
        // setState({ ...state, loading: true })
        let _progress = [];

        let _course_progress = await Service('GET', `course-progress/${_curso.id}/${user.id}`);

        console.log(_course_progress)

        if (_course_progress.response.status === 400) {

            let _body = {
                user: {
                    id: user.id
                },
                course: {
                    id: _curso.id
                },
                progress: JSON.parse(_curso?.data)
            }
            _body.progress.forEach(curso => {
                curso.temas.forEach(tema => {
                    tema.status = false;
                    tema.subtemas.forEach(subtema => {
                        subtema.status = false
                    });
                });
            });
            _progress = _body.progress;
            _body.progress = JSON.stringify(_body.progress);
            console.log(_body)

            // let _new_course_progress = await Service('POST', `course-progress`, _body);

            // console.log(_new_course_progress)
        }


        const { data } = await axios.get(
            `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/testing/entries`,
            {
                baseURL: "",
                params: {
                    content_type: 'tip',
                    access_token: ACCESS_TOKEN,
                },
            }

        );

        // let _pro =  JSON.parse(_curso.data).map(curso => {
        //     curso.temas.forEach(tema => {
        //         tema.status = false;
        //         tema.subtemas.forEach(subtema => {
        //             subtema.status = false
        //             subtema.video = false;
        //             subtema.tip = false;
        //             subtema.documento = false;
        //         });
        //     });
        // });
        // let _pro = JSON.parse(_curso.data)
        // _pro.map((modulo, i) => {
        //     modulo.temas.map((tema, i) => {
        //         tema.status = false;
        //         tema.subtemas.map((subtema, i) => {
        //             subtema.status = false
        //             subtema.status = false;
        //             subtema.video_status = false;
        //             subtema.documento_status = false;
        //         })

        //     })
        // })





        setState({ ...state, tips: data, loading: true, curso: _progress, curso_progress: _progress })
        // setState({ ...state, tips: data, loading: true, curso: JSON.parse(_curso.data), curso_progress: _progress })
    }

    const cursoCompletado = (curso, tema) => {

        let _subtemas_to_check = state.curso[curso].temas[tema];
        let _contador = 0;
        state.curso[curso].temas[tema].subtemas.forEach(subtema => {
            if (subtema.status === true) {
                _contador++
            }
        });
        if (_contador === _subtemas_to_check.subtemas.length) {
            state.curso[curso].temas[tema].status = true;
            setState({ ...state })
        }
    }

    return (
        <div className='container-fluid d-flex h-100'>

            <div>
                <SideMenu page={'home'}></SideMenu>
            </div>

            {/* MODALES */}
            {/* MODALES */}
            <div className='col h-100 overflow-auto hide-scroll'>

                <div className='container-fluid bg-white sticky-top '>
                    <img className='m-3' width={120} src='/assets/images/logo.png'></img>
                </div>
                <div className='container-fluid mx-auto'>
                    <div className='tip-full-aux-detail-plan' style={{ backgroundImage: ` url(/assets/images/header-plan-test.jpg)` }}>

                    </div>

                </div>
                <div className='p-4'>
                    <div className='d-flex'>
                        <div className=''>
                            <IoArrowBackCircle size={40} color='#00BF6B' className='cursor-pointer' onClick={() => {
                                navigate('/user')
                            }} />
                        </div>
                        <div className='w-100'>
                            <div className='d-flex'>


                                {/* MENU DE CURSOS */}
                                <div className='col-6 px-4'>
                                    <div className='d-flex'>
                                        <div className='d-flex-center f-34 bold text-gray '>

                                            {_curso?.title}
                                        </div>
                                        {/* <div className='col'></div> */}
                                        {/* <Renderif isTrue={state.btn_select === 2}>
                                            <div className='d-flex-center text-green text-underline f-14 cursor-pointer'>
                                                Mis Avances
                                            </div>
                                        </Renderif> */}

                                    </div>
                                    <div className='d-flex my-3'>
                                        <div className={`bv-cta f-18 ${state.btn_select === 1 ? 'bv-cta--success' : 'bv-cta--unselect'} w-50 align-self-end me-4`} onClick={() => { setState({ ...state, btn_select: 1, mostrar_semana: 0, tema_dia: null, sub_tema_select_index: null }) }} >
                                            Detalles de plan
                                        </div>
                                        <div className={`bv-cta f-18 ${state.btn_select === 2 ? 'bv-cta--success' : 'bv-cta--unselect'} w-50 align-self-end me-4`} onClick={() => { setState({ ...state, btn_select: 2 }) }} >
                                            Cursos
                                        </div>
                                        <div className={`bv-cta f-18 ${state.btn_select === 3 ? 'bv-cta--success' : 'bv-cta--unselect'} w-50 align-self-end me-4`} onClick={() => { setState({ ...state, btn_select: 3, mostrar_semana: 0, tema_dia: null, sub_tema_select_index: null }) }} >
                                            Comunidad
                                        </div>
                                    </div>

                                    <div>
                                        {/* DETALLES DE PLAN */}
                                        <Renderif isTrue={state.btn_select === 1}>
                                            <div className='detalle text-justify'>
                                                <div dangerouslySetInnerHTML={{ __html: _curso?.description }}></div>
                                            </div>
                                        </Renderif>

                                        {/* CURSOS */}
                                        <Renderif isTrue={state.btn_select === 2}>
                                            <div className='cursos'>
                                                <div className='d-flex-center '>
                                                    <div className='me-2 text-gray f-20'>
                                                        Semana:
                                                    </div>
                                                    {
                                                        // console.log(state.curso)
                                                        state?.curso?.map((item, i) => {
                                                            return (
                                                                <span className={`mx-1 dot-text text-white bold d-flex-center  ${state.mostrar_semana == i ? 'bg-gradient-green' : ''}`}
                                                                    onClick={() => { setState({ ...state, mostrar_semana: i, tema_dia: null, sub_tema_select_index: null }) }}>
                                                                    {i + 1}
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </div>

                                                <div className='text-gray f-22'>
                                                    {state?.curso[state.mostrar_semana]?.name}
                                                </div>
                                                <div>
                                                    {
                                                        state?.curso[state.mostrar_semana]?.temas.map((item, i) => {

                                                            let tema_status = state.curso[state.mostrar_semana].temas[i].status
                                                            return (
                                                                <div>
                                                                    <div className={`d-flex f-22  ${i === 0 ? 'border-bottom-gray' : 'border-bottom-gray'}`}>
                                                                        <div>
                                                                            <Renderif isTrue={state.tema_dia === i}><IoIosArrowDropupCircle size={36} color='#00BF6B' className='cursor-pointer' onClick={() => { setState({ ...state, tema_dia: i, sub_tema_select_index: null }) }} /></Renderif>
                                                                            <Renderif isTrue={state.tema_dia !== i}><IoIosArrowDropdownCircle size={36} color='#777777' className='cursor-pointer' onClick={() => { setState({ ...state, tema_dia: i, sub_tema_select_index: null }) }} /></Renderif>
                                                                        </div>
                                                                        <div className={`${state.tema_dia == i ? 'text-green' : 'text-gray'} ps-2`}><span className='bold'>Día {i + 1}</span> - {item.name}</div>
                                                                        <div className='col'></div>
                                                                        <div className='f-16 text-gray d-flex-center me-3 w-100px'> {state.curso[state.mostrar_semana].temas[i].status === true ? <span>Completado</span> : <span>En curso...</span>}</div>
                                                                        <div className=''
                                                                        // onClick={() => {
                                                                        //     state.curso[state.mostrar_semana].temas[i].status = true;
                                                                        //     setState({ ...state })
                                                                        // }}
                                                                        ><FaHeart size={32} color={tema_status === true ? 'red' : 'gray'} /></div>
                                                                    </div>
                                                                    {/* SUB TEMAS */}
                                                                    <Renderif isTrue={state.tema_dia === i}>
                                                                        {
                                                                            item?.subtemas?.map((subitem, sub_i) => {
                                                                                // console.log(subitem)
                                                                                return (
                                                                                    <div className='ms-4 ps-4 me-2 b-bottom'>
                                                                                        <div className='d-flex'>
                                                                                            <div className={`cursor-pointer ${state.sub_tema_select == 1 && state.sub_tema_select_index === sub_i ? 'text-green' : 'text-gray'}`} onClick={() => {
                                                                                                setState({ ...state, sub_tema_select: 1, sub_tema_select_index: sub_i, tip_asset: state?.curso[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.tip[0] });
                                                                                            }}>
                                                                                                {subitem.name}
                                                                                            </div>
                                                                                            <div className='col'>
                                                                                            </div>
                                                                                            <div>
                                                                                                <FaCheckCircle size={18} color={subitem.status === true ? 'green' : '#777777'} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Renderif>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>


                                            </div>
                                        </Renderif>

                                        {/* COMUNIDAD */}
                                        <Renderif isTrue={state.btn_select === 3}>
                                            <div className='comunidad'>
                                                <div className='my-3 text-gray f-26 lh-sm'>
                                                    <div>
                                                        ¿Tienes una duda o pregunta?
                                                    </div>
                                                    <div>
                                                        Habla con la comunidad.
                                                    </div>
                                                </div>
                                                <div className='text-gray f-16'>
                                                    Haz tu pregunta. (Máximo 400 Caracteres).
                                                </div>
                                                <div>
                                                    <textarea className='w-100' maxLength="400"></textarea>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className='col'></div>
                                                    <div className='bv-cta bv-cta--success w-25 align-self-end my-2 f-18'>
                                                        Publicar Pregunta
                                                    </div>
                                                </div>
                                            </div>
                                        </Renderif>
                                    </div>
                                </div>
                                {/* FIN MENU DE CURSOS */}



                                {/* <div className='border-left-gray'></div> */}
                                {/* RESULTADOS DEL MENU DE CURSOS */}
                                <div className='col-6 px-4'>
                                    <Renderif isTrue={state.btn_select === 1}>

                                    </Renderif>

                                    <Renderif isTrue={state.btn_select === 2}>

                                        <div className='cursos'>
                                            <Renderif isTrue={state.sub_tema_select === 1 && state.sub_tema_select_index !== null}>
                                                <div className='tip'>{ }
                                                    <div className='tip-full-aux-detail-plan' style={{ backgroundImage: ` url(${state.tips?.includes?.Asset.find((item) => item.sys.id === state.tip_asset)?.fields?.file?.url})` }}>
                                                    </div>
                                                    <div className='h4 text-gray my-2'>
                                                        {state?.curso[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.name}
                                                    </div>
                                                    <div className='text-gray text-justify f-14'>
                                                        <div dangerouslySetInnerHTML={{ __html: state?.curso[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.description }}></div>
                                                    </div>
                                                    <div className='text-gray f-14'>
                                                        {state?.curso[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.tip.map((tip) => {
                                                            let _tip = state.tips.items.find((item) => item.sys.id === tip)
                                                            // console.log(_tip)
                                                            return (
                                                                <div>
                                                                    <div className='bold f-22 text-gray my-2'>{_tip.fields.title}</div>
                                                                    {_tip.fields.description.content.map((content) => {
                                                                        if (content.nodeType === "paragraph") {
                                                                            return (
                                                                                <div className='text-justify'>
                                                                                    {content.content.map((element) => {
                                                                                        if (element.nodeType === "hyperlink") {
                                                                                            return (

                                                                                                <a href={`${element.data.uri}`} target='_blank'>{element.data.uri}</a>
                                                                                            )
                                                                                        } else if (element.marks?.find((bold) => bold?.type === "bold")) {
                                                                                            <span className='bold'>{element.value}</span>
                                                                                        } else {
                                                                                            return (
                                                                                                <span>{element.value}</span>
                                                                                            )
                                                                                        }



                                                                                    })}
                                                                                </div>
                                                                            )
                                                                        }
                                                                        else if (content.nodeType === "unordered-list") {
                                                                            return (
                                                                                <ul>
                                                                                    {content.content.map((element) => {
                                                                                        return (
                                                                                            <li>
                                                                                                {element.content[0].content.map((li) => {

                                                                                                    if (li.marks?.find((bold) => bold?.type === "bold")) {
                                                                                                        return (
                                                                                                            <span className='bold'>{li.value}</span>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <span>{li.value}</span>
                                                                                                        )
                                                                                                    }
                                                                                                })}
                                                                                            </li>
                                                                                        )


                                                                                    })}
                                                                                </ul>
                                                                            )
                                                                        }
                                                                        else if (content.nodeType === "blockquote") {
                                                                            return (
                                                                                <div>
                                                                                    {content.content.map((element) => {
                                                                                        return (
                                                                                            <div>{element.content[0].value}</div>
                                                                                        )


                                                                                    })}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <Renderif isTrue={state?.curso[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.video.length > 0}>
                                                        <div className='tip-full-aux-detail-plan' >
                                                            <iframe class="h-100 w-100" src={state?.curso[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.video} allowfullscreen></iframe>
                                                        </div>
                                                    </Renderif>
                                                    {/* MOSTRAR DOCUMENTO */}
                                                    <Renderif isTrue={state?.curso[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.documento.length > 0}>

                                                    </Renderif>
                                                    <div className='my-4'>
                                                        <div className='d-flex'>
                                                            <div className='col'>
                                                            </div>
                                                            <div className='my-2'>
                                                                <PiSpeakerHighBold size={34} color='white' className='bg-gradient-green r-25 p-1 me-2 cursor-pointer' onClick={() => {

                                                                }} />
                                                                <RxSize size={34} color='white' className='bg-gradient-green r-25 p-1 cursor-pointer' onClick={() => {

                                                                }} />
                                                            </div>
                                                        </div>
                                                        <div >
                                                            <embed src='/Lorem_ipsum.pdf#zoom=90&navpanes=0' type='application/pdf' height={"400px"} width={"100%"}></embed>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={`bv-cta bv-cta--success w-50 align-self-end my-2 f-18 ${state?.curso[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.status === false ? `` : `d-none`}`} onClick={() => {
                                                            state.curso[state.mostrar_semana].temas[state.tema_dia].subtemas[state.sub_tema_select_index].status = true
                                                            setState({ ...state })
                                                            cursoCompletado(state.mostrar_semana, state.tema_dia);
                                                        }}>
                                                            Marcar Completado
                                                        </div>
                                                    </div>
                                                </div>
                                            </Renderif>
                                            <Renderif isTrue={state.tema_dia !== null && state.sub_tema_select_index === null}>
                                                <div className='text-gray text-justify f-14'>
                                                    <div dangerouslySetInnerHTML={{ __html: state?.curso[state.mostrar_semana]?.temas[state.tema_dia]?.description }}></div>
                                                </div>
                                            </Renderif>
                                        </div>

                                    </Renderif>

                                    <Renderif isTrue={state.btn_select === 3}>
                                        <div className='comunidad '>
                                            <div className='comentario d-flex my-2'>
                                                <div className='me-2'>
                                                    <img src='/assets/images/user.png' className='user-image m-2'></img>
                                                </div>
                                                <div className='contenido f-14 text-gray'>
                                                    <div><span className='bold f-16'>Usuario123</span> - 1 de noviembre de 2024</div>
                                                    <div>¿tengo una pregunta me la podrían responder?</div>
                                                    <div className='text-gray-30 bold'>
                                                        <span className='me-3'>Responder</span>
                                                        <span><FaHeart size={20} color='#BEC3C9' className='me-1' />234</span>
                                                    </div>
                                                    <div className='bold text-gray-30'>
                                                        Ver 123 respuestas mas
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='comentario d-flex my-2'>
                                                <div className='me-2'>
                                                    <img src='/assets/images/user.png' className='user-image m-2'></img>
                                                </div>
                                                <div className='contenido f-14 text-gray'>
                                                    <div><span className='bold f-16'>Usuario123</span> - 1 de noviembre de 2024</div>
                                                    <div>¿tengo una pregunta me la podrían responder?</div>
                                                    <div className='text-gray-30 bold'>
                                                        <span className='me-3'>Responder</span>
                                                        <span><FaHeart size={20} color='#BEC3C9' className='me-1' />234</span>
                                                    </div>
                                                    <div className='bold text-gray-30'>
                                                        Ver 123 respuestas mas
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Renderif>




                                </div>
                                {/* FIN DE RESULTADOS DEL MENU DE CURSOS */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
