import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SideMenu from '../components/sidemenu'
import Header from '../components/header'
import { Service } from '../services/api'
import { BiArrowBack } from 'react-icons/bi'

export default function PreciosView() {
    const navigate = useNavigate()
    const [state, setState] = useState()

    const onPurchase = async (id_plan, name) => {
        let body = {
            priceId: id_plan,
            name
        }
        let _purchase = await Service('POST', `plans/checkoutSession`, body)
        console.log(_purchase);
        window.open(_purchase.data.url, '_blank')

    }


    return (
        <div className='container-fluid d-flex h-100'>


            <div>
                <SideMenu page="perfil"></SideMenu>
            </div>

            <div className='col'>
                <Header></Header>
                <div className='container'>
                    <div className='d-flex p-3'>

                        {/* <div className='tabs'>
                    <div className='bv-tab' onClick={()=>{
                        setState({...state, tab:0})
                    }}>Citas</div>
                    <div className='bv-tab bv-tab--active'>Especialidades</div>
                
                </div> */}






                    </div>
                    <div className='container px-3'>
                        <div className='cta-back' onClick={() => {
                            navigate(-1)
                        }}>
                            <div className='bv-icon bv-icon--mid'>
                                <BiArrowBack size={18} color='#00bf6b'></BiArrowBack>
                            </div>
                            <div className='f-14 text-green'>Regresar</div>
                        </div>

                        <div className='container pb-3'>
                            <div className='f-28 text-gray bold'>PLANES ALIMENTICIOS</div>
                            <div className='f-16 text-gray'>Tips y recetas sencillas elaboradas por nutriólogas certificadas</div>
                        </div>


                        <div className='d-flex'>
                            {/* <stripe-pricing-table className="d-flex flex-column" pricing-table-id="prctbl_1PW5GPBaFeuuZx1IzTQwhE6C"
publishable-key="pk_test_51Or0vcBaFeuuZx1Il9PbOkF3hJ7iyo3RSuZLHtIagH6xX0tU1rYppvrRsLJkdiGGaY5r6sWnwHRBn0WSXH9CuFke00NsUZYx18">
</stripe-pricing-table> */}
                            {/* <div className='col pe-3'>
                        <img width={"100%"} className='cursor-pointer' onClick={()=>{
                            onPurchase("price_1PRIGgBaFeuuZx1I7Xa3wJM2","PLAN FUNCIONAL" )
                            // navigate('/user/agendar/1')
                        }} src='/assets/images/s1.png'></img>
                    </div>

                    <div className='col pe-3'>
                        <img width="100%" className='cursor-pointer' onClick={()=>{
                            onPurchase("price_1PRHnZBaFeuuZx1IDDtgACuM","PLAN FUNCIONAL PLUS")
                            // navigate('/user/agendar/2')
                        }} src='/assets/images/s2.png'></img>
                    </div>
                    <div className='col'></div> */}
                            <div className="row">
                                <div className="plan-container">
                                    <div className="image-container">
                                        <img src="/assets/images/plan_funcional.png" className="img-plan"></img>
                                    </div>
                                    <div className="plan-description">
                                        <div className='f-26 bold'>PLAN FUNCIONAL</div>
                                        <div className='f-16 text-gray'>
                                            Al seleccionar el plan, tendrás acceso a Tips, Videos y Documentos en Aprende Más. Recetas
                                            Personalizadas al plan que decidiste comprar. Chat directo con Especialistas en Nutricion,
                                            Ginecologia y Psicologia.</div>
                                    </div>
                                    <div className="plan-price">
                                        <div className='f-28 bold'>MXN 99</div><div className='por-mes text-gray'><div className='mes'>por</div><div>mes</div></div>
                                    </div>
                                    <div className="sus-container">
                                        <div className="suscription-button cursor-pointer" onClick={() => {
                                            onPurchase("price_1PW1KDBaFeuuZx1IIX8VeUfQ", "PLAN FUNCIONAL")
                                            // navigate('/user/agendar/1')
                                        }}>Suscribirse</div>
                                    </div>
                                    <div className="desc-container f-16 text-gray">
                                        <p>Esto incluye:</p>
                                        <ul>
                                            <li>
                                                Acceso a recetas del plan seleccionado.</li>
                                            <li>Tips personalizados.
                                            </li>
                                            <li>Documentos relevantes.</li>
                                            <li>
                                                Videos informativos.</li>
                                            <li>Chat con Especialistas de tu elección.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="plan-container">
                                    <div className="image-container">
                                        <img src="/assets/images/plan_funcional_plus.png" className="img-plan"></img>
                                    </div>
                                    <div className="plan-description">
                                        <div className='f-26 bold'>PLAN FUNCIONAL PLUS</div>
                                        <div className='f-16 text-gray'>
                                            El Plan Funcional Plus te ofrece acceso a recetas, tips, documentos y videos del plan seleccionado. Además, incluye una cita con cualquier especialista, sin restricciones de especialidad. ¡Aprovecha este plan integral para tu salud!</div>
                                    </div>
                                    <div className="plan-price">
                                        <div className='f-28 bold'>MXN 790</div><div className='por-mes text-gray'><div className='mes'>por</div><div>mes</div></div>
                                    </div>
                                    <div className="sus-container">
                                        <div className="suscription-button cursor-pointer" onClick={() => {
                                            onPurchase("price_1PRIU4BaFeuuZx1IPRzlvOUe", "PLAN FUNCIONAL PLUS")
                                            // navigate('/user/agendar/2')
                                        }}>Suscribirse</div>
                                    </div>
                                    <div className="desc-container f-16 text-gray">
                                        <p>Esto incluye:</p>
                                        <ul>
                                            <li>
                                                Acceso a recetas del plan seleccionado.
                                            </li>
                                            <li>Tips personalizados.
                                            </li>
                                            <li>Documentos relevantes.</li>
                                            <li>
                                                Videos informativos.</li>
                                            <li>1 cita con cualquier especialista (sin restricciones de especialidad).</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
